<template>
  <div v-if="!user || NFTs.length === 0" class="justify-center flex bg-yellow-300 items-center h-screen">
    <div class="text-center pb-60">
      <h1 class="text-4xl mb-6">Do you know what's cool? <br>Actually having a NFT. 😎</h1>
      <p>It seems like you do not have any NFT just yet. <br>Why don't you head on to arteesan.io to look for something
        you like.</p>
    </div>
  </div>

  <div v-if="user" class="bg-gradient-to-l from-red-700 to-pink-600 min-h-screen pt-8">
    <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 p-8">
      <div v-for="(nft, index) in NFTs" :key="index"
           class="relative border-2 border-solid border-white rounded-3xl col-span-1 bg-white" style="height: 35rem;">
        <img :src="nft.imageUrl"
             class="w-full h-3/4 bg-gray-200 object-contain rounded-3xl border-2 border-white" alt="">
        <!-- BOTTOM ICONS -->
        <div class="p-3">
          <h1 class="mb-2">{{ nft.metadata.name }}</h1>
          <p class="text-xs overflow-ellipsis">{{ nft.metadata.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  computed: {
    user() {
      return this.$store.state.user;
    },
    NFTs() {
      return this.$store.state.NFTs;
    }
  },
}
</script>
