import {createStore} from "vuex";
import MoralisService from "@/services/MoralisService";

export const store = createStore({
    state() {
        return {
            user: null,
            NFTs: []
        }
    },
    mutations: {
        login(state, user) {
            state.user = user;
        },
        logout(state) {
            state.user = null;
        },
        replaceNFTs(state, NFTs) {
            state.NFTs = NFTs;
        }
    },
    actions: {
        login(context) {
            MoralisService.login().then((user) => {
                context.commit('login', user);
                this.dispatch('getNFTs');
            });
        },
        logout(context) {
            MoralisService.logout().then(() => {
                context.commit('logout');
            })
        },
        getNFTs(context) {
            MoralisService.getNFT().then(NFTs => {
                context.commit('replaceNFTs', NFTs);
            })
        }
    }
})
