import Moralis from "moralis";
import {store} from "@/store";

export default {
    init() {
        Moralis.start({
            serverUrl: process.env.VUE_APP_MORALIS_SERVER_URL,
            appId: process.env.VUE_APP_MORALIS_APP_ID
        });

        Moralis.User.currentAsync().then(function() {
            store.dispatch('login');
        });
    },

    async login() {
        return new Promise((resolve, reject) => {
            let user = Moralis.User.current();
            if (user) {
                resolve(user);
            }

            if (!user) {
                Moralis.authenticate({signingMessage: "Sign into Arteevue"})
                    .then(function (user) {
                        resolve(user);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            }
        })
    },

    async logout() {
        return await Moralis.User.logOut();
    },

    async getNFT() {
        return Moralis.Web3API.account.getNFTs().then(userEthNFTs => {
            return userEthNFTs.result.map(nft => {
                nft.metadata = JSON.parse(nft.metadata);
                nft.imageUrl = nft.metadata.image.replace('ipfs://ipfs/', 'https://ipfs.io/ipfs/');
                // nft.imageUrl = nft.metadata.image.replace('ipfs://ipfs/', 'https://ipfs.moralis.io:2053/ipfs/');
                return nft;
            });
        });
    }
}




