<template>
  <navbar></navbar>
  <router-view/>
</template>
<script>
import Navbar from "./components/Navbar";
import MoralisService from "@/services/MoralisService";

export default {
  components: {Navbar},
  setup() {
    MoralisService.init();
  },
}
</script>
